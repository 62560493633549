<template>
  <div id="app" class="overflow-x-hidden">
    <Header></Header>
    <main class="max-w-screen-xl m-auto px-8">
      <router-view />
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "app",

  components: {
    Header,
    Footer,
  },
};
</script>

<style>
main {
  background: url(./assets/img/shape-back.svg),
    url(./assets/img/shape-front.svg);
  background-attachment: scroll, local;
  background-repeat: no-repeat;
  background-size: 120%, 120%;
  background-position: top 18% left 40%, top 90% right 5%;
}

@media screen and (min-width: 768px) {
  main {
    background: url(./assets/img/shape-back.svg),
      url(./assets/img/shape-front.svg);
    background-attachment: scroll, local;
    background-repeat: no-repeat;
    background-size: 80%, 80%;
    background-position: top 15% left 40%, top 60% right 5%;
  }
}
</style>
