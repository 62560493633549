<template>
  <div class="w-full relative z-10 sm:flex sm:justify-between">
    <div class="card card-hero mb-8 sm:mb-0">
      <div>
        <svg
          class="h-8 w-8 mb-8 lg:mr-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
          />
        </svg>
      </div>
      <div>
        <h3>Sin terceros que se aprovechen de tu situación</h3>
        <p class="text-lg leading-6">
          Nuestra tarea es evitarte pérdidas de tiempo y dinero, te guiamos en
          el proceso para que obtengas el mejor resultado en tus reclamos a las
          compañías de seguro.
        </p>
      </div>
    </div>

    <div class="card card-hero">
      <div>
        <svg
          class="h-8 w-8 mb-8 lg:mr-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div>
        <h3 class="xl:w-3/4">Totalmente gratis y transparente</h3>
        <p class="text-lg leading-6">
          Realizá todo el proceso sin intermediarios, nosotros te guiamos y
          juntos presentaremos tu reclamo.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHero",
};
</script>
