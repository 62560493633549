<template>
  <!-- modal -->
  <div
    v-if="toggleModal"
    class="
      fixed
      overflow-x-hidden
      overscroll-y-auto
      inset-0
      flex
      justify-center
      items-center
      z-40
    "
  >
    <div class="relative mx-auto w-auto max-w-2xl">
      <div class="bg-white w-full p-6 rounded-xl shadow-2xl">
        <video
          controls="controls"
          poster="../assets/img/postervideo.png"
          class="focus:outline-none"
        >
          <source src="../assets/video/clicSocial.mp4" type="video/mp4" />
          Your browser does not support the HTML5 Video element.
        </video>
        <div class="flex mt-4 justify-between sm:items-center">
          <div class="flex flex-col sm:flex-row">
            <a
              href="https://wa.me/5492616502203"
              class="btn btn-outline-primary px-8 py-2 mb-2 sm:mr-2 sm:mb-0"
              >Solicitar Asesoramiento</a
            >
            <a
              href="https://forms.gle/pX6fpDiuoPGh3A9M6"
              target="_blank"
              class="btn btn-outline-primary px-8 py-2"
              >Enviar Reclamo</a
            >
          </div>
          <div class="text-right">
            <button
              class="btn btn-outline-primary p-2"
              @click="toggleModal = false"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="toggleModal" class="fixed z-30 inset-0 opacity-25 bg-black"></div>

  <!-- intro -->
  <section class="relative mb-16">
    <h1
      class="
        w-full
        mb-4
        md:w-1/2 md:mb-10
        lg:w-1/2 lg:ml-8
        xl:w-5/12 xl:mb-20 xl:mt-8
        text-left
        sm:text-center
        md:text-left
      "
    >
      ¿Tuviste un accidente de tránsito? Te ayudamos a reclamar gratis.
    </h1>
    <div class="md:absolute md:right-0 md:top-0 md:-mr-24 lg:-mr-12 xl:mr-16">
      <img src="../assets/img/abrazo.svg" alt="Abrazo" />
    </div>
    <CardHero />
  </section>

  <section class="relative mb-10 sm:mb-16">
    <div class="flex justify-center">
      <div class="flex flex-col mb-10 lg:mb-0 lg:mt-8 lg:w-1/2 text-center">
        <h2>¿Cómo funciona?</h2>
        <p class="text-lg leading-6 mt-4">
          Es fácil, rápido y seguro! <br />
          Hace tu reclamo en cuatro pasos
        </p>
        <div class="inline-block">
          <button
            class="btn btn-secondary focus:outline-none mt-8 px-8 py-2"
            @click="toggleModal = !toggleModal"
          >
            Ver video
          </button>
        </div>
      </div>
    </div>
    <CardFeature />
  </section>

  <section class="relative mb-72 sm:mb-8 lg:mb-20">
    <h2
      class="
        w-full
        mb-4
        sm:mb-10
        md:mb-10
        lg:w-1/2 lg:ml-8
        xl:w-5/12 xl:mb-20 xl:mt-8
        text-center
        md:text-left
      "
    >
      Ayudanos a ayudar
    </h2>
    <div class="card card-hero mb-8 md:mb-0">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-8 w-8 mb-8 lg:mr-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
          />
        </svg>
      </div>
      <div>
        <h3 class="xl:w-3/4">Hagamos clic</h3>
        <p class="text-lg leading-6 mb-4">
          Realizá todo el proceso en clic.social de manera gratuita y al
          finalizar, si querés, podés hacer una donación para contribuir a que
          más personas puedan gestionar sus siniestros en nuestro sitio.
        </p>
        <p class="text-lg leading-6">
          Y si sos abogado o profesional y querés participar ayudando de alguna
          manera, comunícate con nosotros.
        </p>
      </div>
    </div>

    <div
      class="
        absolute
        -bottom-72
        flex
        justify-center
        sm:justify-end
        xl:justify-center
        items-center
        w-full
        sm:w-3/5 sm:bottom-0 sm:top-0 sm:right-0 sm:mr-4
        md:mr-0 md:top-24
        lg:w-1/2
      "
    >
      <img
        src="../assets/img/ayudanos.svg"
        alt="Ayudanos"
        class="w-11/12 sm:w-full xl:w-4/5"
      />
    </div>
  </section>
</template>

<script>
import CardHero from "@/components/CardHero";
import CardFeature from "@/components/CardFeature";

export default {
  components: {
    CardHero,
    CardFeature,
  },
  data() {
    return {
      toggleModal: false,
    };
  },
};
</script>
