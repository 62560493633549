<template>
  <div class="relative sm:flex sm:justify-between sm:items-center">
    <div class="flex justify-center sm:w-1/2 lg:w-1/3">
      <img
        src="../assets/img/espanta.svg"
        alt="Espanta Cuervos"
        class="w-3/4 sm:w-full"
      />
    </div>
    <div
      class="
        w-full
        mt-8
        sm:mt-0 sm:absolute sm:right-0 sm:w-3/5
        lg:w-3/4
        xl:mt-24
      "
    >
      <swiper
        class="pb-14"
        :pagination="{ clickable: true }"
        :slides-per-view="1"
        :space-between="30"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :breakpoints="{
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }"
      >
        <swiper-slide>
          <div class="card card-feature">
            <div>
              <svg
                class="h-8 w-8 mb-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                />
              </svg>
            </div>
            <div>
              <h3 class="text-xl">1 . Reclamo</h3>
              <p>
                Completa nuestro
                <a
                  href="https://forms.gle/pX6fpDiuoPGh3A9M6"
                  target="_blank"
                  class="text-primary-900 underline"
                  >formulario</a
                >
                y nos pondremos en contacto a la brevedad.
              </p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="card card-feature card-feature_active">
            <div>
              <svg
                class="h-8 w-8 mb-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div>
              <h3 class="text-xl">2 . Viabilidad</h3>
              <p>
                Analizamos la viabilidad de tu caso y te avisamos si tenes
                derecho a reclamar.
              </p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="card card-feature">
            <div>
              <svg
                class="h-8 w-8 mb-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
            </div>
            <div>
              <h3 class="text-xl">3 . Gestión</h3>
              <p>
                En caso de que puedas reclamar, iniciaremos negociaciones con
                las compañias.
              </p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="card card-feature">
            <div>
              <svg
                class="h-8 w-8 mb-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div>
              <h3 class="text-xl">4 . Cobro</h3>
              <p>
                Por último, si alguna propuesta indemnizatoria te conviene, la
                aceptas y recibís tu dinero.
              </p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination]);

export default {
  name: "CardFeature",
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>
